import { alpha, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

export const useHeaderStyles = makeStyles<Theme>(theme => ({
  root: {
    padding: theme.spacing(1.2, 0),

    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(3, 0),
    },
  },
  rootUnderline: {
    [theme.breakpoints.up('md')]: {
      borderBottom: `1px solid ${alpha(theme.palette.common.black, 0.1)}`,
    },
  },
  container: {
    display: 'flex',
    gap: theme.spacing(3.5),
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: 52,

    [theme.breakpoints.up('xl')]: {
      padding: theme.spacing(0, 5),
    },
  },
  logo: {
    '& span': {
      color: theme.palette.text.disabled,
      backgroundColor: 'transparent',
      alignSelf: 'flex-start',
    },
  },
  search: {
    justifySelf: 'end',
    height: 52,
    width: '100%',
    maxWidth: 760,
    paddingLeft: theme.spacing(3),
  },
}));
